// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"
import play from "./PlayIcon.svg"

var classNames = require("classnames")

class VideoButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var btnClass = classNames({
      "standard-button": true,
      "button-video": false,
      contained: this.props.contained,
      "video-button": this.props.overVideo === false ? false : false,
      "z-1": this.props.overVideo,
      "sizzle-button-under": this.props.sizzleButton,
      minimal: this.props.minimal ? this.props.minimal : false,
      "btn-left": this.props.textAlign === "left",
      "btn-right": this.props.textAlign === "right",
      "btn-center": this.props.textAlign === "center"
    })

    if (this.props.onlyPlayIcon) {
      return (
        <div
          className="play-circle standalone"
          onClick={this.props.toggleTheater}>
          {/* <FontAwesomeIcon className="play-icon" icon={faPlay} /> */}
          <img className="play-icon" src={play} />
        </div>
      )
    }

    // return (
    //     <img onClick={this.props.toggleTheater} className="button-icon" src={play} />
    // )

    return (
      <svg
        onClick={this.props.toggleTheater}
        className="button-icon svg-button"
        id="svg-button"
        viewBox="0 0 200 200">
        <defs>
          <linearGradient
            id="gradient"
            x1="29.29"
            y1="29.29"
            x2="170.71"
            y2="170.71"
            gradientUnits="userSpaceOnUse">
            {/* <stop className="stop-1" offset="0" stopColor="#ffffff" />
            <stop className="stop-2" offset="0.2" stopColor="#ffffff" />
            <stop className="stop-3" offset="1" stopColor="#ffffff" /> */}
          </linearGradient>
          <mask id="myMask" x="0" y="0" width="100%" height="100%">
            <rect width="100%" height="100%" fill="white" />
            <circle
              className="outerMask"
              cx="100"
              cy="100"
              r="95"
              fill="gray"
            />
            <circle
              className="innerMask"
              cx="100"
              cy="100"
              r="95"
              fill="black"
            />
          </mask>
        </defs>
        <circle
          className="cls-1"
          cx="100"
          cy="100"
          r="100"
          mask="url(#myMask)"
          fill="url(#gradient)"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          className="play-icon"
          d="M81,71.14v57.72A3.74,3.74,0,0,0,86.82,132l43.3-28.86a3.76,3.76,0,0,0,0-6.24L86.82,68A3.74,3.74,0,0,0,81,71.14Z"
          fill="white"
        />
      </svg>
    )
  }
}

VideoButton.propTypes = {
  minimal: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  overVideo: PropTypes.bool,
  toggleTheater: PropTypes.func
}

export default VideoButton
