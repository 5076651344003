import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import { Section } from "../Layouts"

const Portfolio = ({ bgColor, listColor }) => {
  const data = useStaticQuery(graphql`
    query portfolioQuery {
      allDataJson(filter: { optionsItem: { eq: "portfolio" } }) {
        nodes {
          bottomHeader
          topHeader
          categories {
            category
            content {
              title
            }
          }
        }
      }
    }
  `)

  const portfolioData = data.allDataJson.nodes[0]
  const { categories, topHeader, bottomHeader } = portfolioData

  const renderCategories = () => {
    return categories.map((categoryContent, i) => {
      const { category, content } = categoryContent
      return (
        <div className="portfolio-category" key={i}>
          <span role="heading" className="portfolio-category__title">
            {category}
          </span>
          <ul className="portfolio-category__content" key={i}>
            {content.map((item, i) => (
              <li key={i}>{item.title}</li>
            ))}
          </ul>
        </div>
      )
    })
  }

  const mainClasses = classNames("portfolio-container", {
    "bg-tan": bgColor && bgColor.toLowerCase() === "tan",
    "list-white": listColor && listColor.toLowerCase() === "white"
  })

  return (
    <Section zeroTop zeroBottom className={mainClasses}>
      <div className="container">
        <h2 className="portfolio-container__top-header">{topHeader}</h2>
        <h2 className="portfolio-container__bottom-header">{bottomHeader}</h2>
        <div className="portfolio-category-container">{renderCategories()}</div>
      </div>
    </Section>
  )
}

export default Portfolio
